import React from 'react'

// GamePage.jsx
const GamePage = () => {
  return (
    <iframe
      src='/game/index.html'
      width='100%'
      height='600px'
      style={{ border: 'none' }}
      title='Unity Game'
    />
  )
}
export default GamePage
