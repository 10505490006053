import React, { useState } from 'react'
import './Gallery.css'
import SelectedImage from '../SelectedImage/SelectedImage'

const paintingsDir = 'images/paintings/'

const Gallery = ({ items = [], galleryName = '', allowUpload = false }) => {
  const [selectedImage, setSelectedImage] = useState(null)
  const [showDetails, setShowDetails] = useState(true)
  const [showGallery, setShowGallery] = useState(true)
  const [currentImage, setCurrentImage] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [uploadStatus, setUploadStatus] = useState('')

  const handleImageClick = image => {
    setSelectedImage(image)
  }

  const closeDetails = () => setShowDetails(false)

  const handleUpload = async event => {
    const files = event.target.files
    if (!files.length) return

    setUploading(true)
    setUploadStatus('Uploading...')

    const formData = new FormData()
    for (const file of files) {
      formData.append('files', file)
    }
    formData.append('galleryName', galleryName)

    try {
      const res = await fetch('/upload', {
        method: 'POST',
        body: formData,
      })

      const data = await res.json()

      if (res.ok) {
        setUploadStatus(`Uploaded ${files.length} file(s) successfully.`)
        // You can refresh gallery here if needed
      } else {
        setUploadStatus(`Upload failed: ${data.message}`)
      }
    } catch (err) {
      setUploadStatus('Upload failed.')
      console.error(err)
    } finally {
      setUploading(false)
    }
  }

  return (
    <div className="parent">
      {allowUpload && (
        <div className="upload-section">
          <label>
            Upload images/videos:
            <input
              type="file"
              multiple
              accept="image/*,video/mp4"
              onChange={handleUpload}
              disabled={uploading}
            />
          </label>
          {uploadStatus && <p>{uploadStatus}</p>}
        </div>
      )}

      {showGallery && (
        <div className="gallery">
          {items.map((image, index) => {
            const fileExtension = image.url[0].split('.').pop()
            const mediaElement =
              fileExtension === 'mp4' ? (
                <video
                  className="gallery-item"
                  src={`${paintingsDir}${image.url[0]}`}
                  autoPlay
                  muted
                  onClick={() => handleImageClick(image)}
                />
              ) : (
                <img
                  src={`${paintingsDir}${image.url[0]}.jpg`}
                  alt={`Gallery item ${index}`}
                  onClick={() => handleImageClick(image)}
                />
              )

            return (
              <div key={index} className="gallery-item">
                {mediaElement}
              </div>
            )
          })}

          {/* Image Viewer Modal */}
          {selectedImage && (
            <div className="modal">
              {selectedImage.url.length > 1 && (
                <p className="arrow left" onClick={() =>
                  setCurrentImage(prev =>
                    prev === 0
                      ? selectedImage.url.length - 1
                      : prev - 1
                  )}>
                  <span className="highlight">{'<'}</span>
                </p>
              )}

              <SelectedImage
                image={selectedImage.url[currentImage]}
                callback={() => {
                  setSelectedImage(null)
                  setCurrentImage(0)
                  setShowDetails(true)
                }}
              />

              {selectedImage.url.length > 1 && (
                <p className="arrow right" onClick={() =>
                  setCurrentImage(prev =>
                    prev === selectedImage.url.length - 1
                      ? 0
                      : prev + 1
                  )}>
                  <span className="highlight">{'>'}</span>
                </p>
              )}

              {showDetails && (
                <div className="details" onClick={closeDetails}>
                  <div className="x-button">X</div>
                  {selectedImage.title && (
                    <div>כותרת: {selectedImage.title} <br /></div>
                  )}
                  {selectedImage.description && (
                    <div>תיאור: {selectedImage.description} <br /></div>
                  )}
                  גודל: {selectedImage.size} <br />
                  מדיום: {selectedImage.medium} <br />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Gallery
